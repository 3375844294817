export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

const isNotServer = typeof window !== 'undefined';
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  try {
    isNotServer &&
    //@ts-ignore
    window?.gtag &&
    //@ts-ignore
    window?.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });

  isNotServer &&
    //@ts-ignore
    window?.Intercom &&
    //@ts-ignore
    window?.Intercom('update', {
      last_request_at: parseInt(`${new Date().getTime() / 1000}`),
    });
  } catch (error) {
    console.error('Error tracking pageview', error);
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  try {
    isNotServer &&
    //@ts-ignore
    window?.gtag &&
    //@ts-ignore
    window?.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  } catch (error) {
    console.error('Error tracking event', error);
  }
};
