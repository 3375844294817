const storage = {
  set: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (_error) {}
  },
  get: (key) => {
    let result;
    try {
      result = localStorage.getItem(key);
      return JSON.parse(result);
    } catch (error) {
      return result;
    }
  },
  delete: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (_error) {}
  },
};

export default storage;
