import React from 'react';

const Logo = ({color = 'light', height = "32px"}) => {
  let textColor = '#FFFFFF';
  if (color !== 'light') {
    textColor = '#1C1633'
  }
  return (
    <svg height={height} className={`postdrop-logo`} viewBox="0 0 255 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="postdrop-logo__logotype">
        <path fillRule="evenodd" clipRule="evenodd" d="M58.9815 24.6278C59.9019 23.5918 60.3628 22.0796 60.3628 20.089C60.3628 18.0994 59.7622 16.6848 58.562 15.846C57.3611 15.0074 55.4946 14.5879 52.9624 14.5879H48.5716V26.182H53.7517C56.3172 26.182 58.0603 25.6639 58.9815 24.6278M64.6303 11.0606C67.0476 13.1165 68.2564 16.274 68.2564 20.5329C68.2564 24.7928 67.0145 27.9087 64.5316 29.8821C62.0477 31.8557 58.2575 32.8424 53.1596 32.8424H48.5715V42.4628H40.875V7.97702H53.0609C58.356 7.97702 62.2128 9.00528 64.6303 11.0606" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M77.9289 29.1915C77.9289 31.3622 78.5454 33.1307 79.7789 34.4951C81.0123 35.8604 82.591 36.5425 84.5152 36.5425C86.4394 36.5425 88.0181 35.8604 89.2516 34.4951C90.4847 33.1307 91.1015 31.3622 91.1015 29.1915C91.1015 27.0207 90.4847 25.2445 89.2516 23.8632C88.0181 22.4817 86.4394 21.791 84.5152 21.791C82.591 21.791 81.0123 22.4817 79.7789 23.8632C78.5454 25.2445 77.9289 27.0207 77.9289 29.1915M98.5513 29.1915C98.5513 33.0396 97.2191 36.2796 94.555 38.9107C91.8907 41.5424 88.5439 42.8574 84.5153 42.8574C80.4858 42.8574 77.1393 41.5424 74.4752 38.9107C71.811 36.2796 70.4792 33.0396 70.4792 29.1915C70.4792 25.3433 71.811 22.0957 74.4752 19.4476C77.1393 16.8003 80.4858 15.476 84.5153 15.476C88.5439 15.476 91.8907 16.8003 94.555 19.4476C97.2191 22.0957 98.5513 25.3433 98.5513 29.1915" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M122.58 34.199C122.58 36.8138 121.618 38.9106 119.694 40.4894C117.77 42.0682 115.328 42.8574 112.367 42.8574C110.394 42.8574 108.338 42.5036 106.201 41.7969C104.062 41.09 102.138 40.0454 100.428 38.6639L103.685 34.0263C106.447 36.1648 109.39 37.2331 112.516 37.2331C113.469 37.2331 114.225 37.0443 114.785 36.6659C115.344 36.2882 115.624 35.7863 115.624 35.1612C115.624 34.5367 115.237 33.9609 114.464 33.4343C113.691 32.9086 112.573 32.4152 111.11 31.9543C109.646 31.4941 108.527 31.0993 107.755 30.7701C106.982 30.4418 106.102 29.9646 105.115 29.3395C103.109 28.0898 102.106 26.2397 102.106 23.7891C102.106 21.3393 103.092 19.3412 105.066 17.7949C107.039 16.2493 109.588 15.476 112.713 15.476C115.837 15.476 118.962 16.529 122.087 18.6336L119.324 23.567C117.021 21.9885 114.727 21.1991 112.442 21.1991C110.155 21.1991 109.013 21.8243 109.013 23.0739C109.013 23.7646 109.374 24.3074 110.098 24.7019C110.821 25.0967 112.022 25.5576 113.7 26.0834C115.377 26.6099 116.569 27.013 117.276 27.292C117.983 27.5718 118.831 28.0074 119.817 28.5994C121.659 29.7181 122.58 31.5842 122.58 34.199" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M134.324 7.97702V15.92H140.738V21.6923H134.324V33.1383C134.324 34.1583 134.587 34.9561 135.114 35.5311C135.639 36.1068 136.265 36.3946 136.988 36.3946C138.37 36.3946 139.537 35.77 140.491 34.5198L143.254 39.7001C140.952 41.8053 138.526 42.8574 135.977 42.8574C133.428 42.8574 131.273 42.0188 129.514 40.3414C127.754 38.664 126.875 36.3784 126.875 33.4837V7.97702H134.324Z" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M151.89 29.2407C151.89 31.3792 152.531 33.1306 153.814 34.495C155.097 35.8603 156.593 36.5424 158.304 36.5424C160.013 36.5424 161.469 35.8603 162.67 34.495C163.87 33.1306 164.47 31.3707 164.47 29.2161C164.47 27.0623 163.87 25.2691 162.67 23.8384C161.469 22.4078 159.997 21.6924 158.254 21.6924C156.511 21.6924 155.014 22.4161 153.765 23.8632C152.514 25.3108 151.89 27.1032 151.89 29.2407M156.552 42.8574C153.411 42.8574 150.607 41.5254 148.14 38.8613C145.673 36.197 144.44 32.8916 144.44 28.9448C144.44 24.9978 145.64 21.7663 148.042 19.2503C150.442 16.7339 153.254 15.476 156.478 15.476C159.701 15.476 162.332 16.6109 164.372 18.8803V5.85559H171.772V42.4629H164.372V38.96C162.3 41.5586 159.692 42.8574 156.552 42.8574" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M191.558 22.4325C189.354 22.4325 187.71 23.214 186.624 24.7758C185.539 26.3385 184.996 28.4021 184.996 30.9676V42.4628H177.596V15.92H184.996V19.423C185.95 18.3374 187.142 17.417 188.573 16.6602C190.004 15.904 191.459 15.5093 192.939 15.476L192.989 22.4325H191.558Z" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M202.217 29.1915C202.217 31.3622 202.834 33.1307 204.067 34.4951C205.301 35.8604 206.879 36.5425 208.803 36.5425C210.727 36.5425 212.306 35.8604 213.54 34.4951C214.773 33.1307 215.39 31.3622 215.39 29.1915C215.39 27.0207 214.773 25.2445 213.54 23.8632C212.306 22.4817 210.727 21.791 208.803 21.791C206.879 21.791 205.301 22.4817 204.067 23.8632C202.834 25.2445 202.217 27.0207 202.217 29.1915M222.839 29.1915C222.839 33.0396 221.507 36.2796 218.843 38.9107C216.179 41.5424 212.832 42.8574 208.803 42.8574C204.774 42.8574 201.427 41.5424 198.763 38.9107C196.099 36.2796 194.767 33.0396 194.767 29.1915C194.767 25.3433 196.099 22.0957 198.763 19.4476C201.427 16.8003 204.774 15.476 208.803 15.476C212.832 15.476 216.179 16.8003 218.843 19.4476C221.507 22.0957 222.839 25.3433 222.839 29.1915" fill={textColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M234.435 29.2161C234.435 31.3706 235.035 33.1306 236.236 34.495C237.436 35.8603 238.892 36.5424 240.602 36.5424C242.312 36.5424 243.809 35.8603 245.092 34.495C246.375 33.1306 247.016 31.3792 247.016 29.2408C247.016 27.1032 246.391 25.3109 245.141 23.8631C243.891 22.4162 242.394 21.6923 240.652 21.6923C238.908 21.6923 237.436 22.4077 236.236 23.8385C235.035 25.2691 234.435 27.0624 234.435 29.2161M250.839 19.3489C253.257 21.9314 254.466 25.1789 254.466 29.0929C254.466 33.0074 253.257 36.2796 250.839 38.9106C248.422 41.5425 245.593 42.8575 242.354 42.8575C239.114 42.8575 236.507 41.5587 234.534 38.9601V49.9932H227.133V15.92H234.534V18.8803C236.803 16.6108 239.484 15.476 242.576 15.476C245.667 15.476 248.422 16.7673 250.839 19.3489Z" fill={textColor}/>
      </g>
      <g className="postdrop-logo__logomark">
        <path className="postdrop-logo__logomark-top" fillRule="evenodd" clipRule="evenodd" d="M14.5942 36.1752C15.7403 37.2616 17.6511 37.261 18.7991 36.1752L31.8878 23.7808C29.2391 15.6866 23.1547 7.0261 16.7081 0C10.2631 7.02413 4.18037 15.6819 1.53064 23.7742L14.5942 36.1752Z" fill="#EC0866"/>
        <path className="postdrop-logo__logomark-right" fillRule="evenodd" clipRule="evenodd" d="M32.6021 27.0408L25.2551 34.0511L31.5237 39.7959C32.5728 37.4123 33.1633 34.7393 33.1633 31.913C33.1633 30.3399 32.9649 28.7086 32.6021 27.0408" fill="#FCBE2D"/>
        <path className="postdrop-logo__logomark-left" fillRule="evenodd" clipRule="evenodd" d="M0.562875 27.0408C0.199153 28.7088 0 30.3399 0 31.913C0 34.7396 0.592452 37.4128 1.64479 39.7959L7.90816 34.0651L0.562875 27.0408Z" fill="#FCBE2D"/>
        <path className="postdrop-logo__logomark-bottom" fillRule="evenodd" clipRule="evenodd" d="M23.2141 36.2421C23.2081 36.2364 23.2021 36.2305 23.1963 36.2245L19.3518 39.795C17.7427 41.2933 15.1393 41.2957 13.5314 39.795L9.72283 36.2245C9.717 36.2305 9.71102 36.2364 9.70504 36.2421L2.80615 42.4262C5.83297 47.009 10.8171 50 16.4509 50C22.0886 50 27.076 47.0048 30.1021 42.4165L23.2141 36.2421Z" fill={textColor}/>
      </g>
    </svg>
  )
};

export default Logo;