import React, { FC, ReactNode, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import classnames from "classnames";
import Logo from "../logo";
export { default as Dashboard } from "./dashboard";

type PProps = {
  className?: string;
  size?: string;
};
export const P: React.FC<PProps> = ({
  children,
  size = "sm",
  className,
  ...attrs
}) => {
  const classes = classnames(
    `mt-2 mb-2 text-gray-600 max-w text-${size}`,
    className
  );
  return (
    <p {...attrs} className={classes}>
      {children}
    </p>
  );
};

// bg-blue
const buttonClasses =
  "whitespace-nowrap border border-transparent rounded-md shadow-sm inline-flex justify-center font-medium text-white";
// bg-transparent
const buttonOutlineClasses =
  "whitespace-nowrap border border-blue rounded-md shadow-sm inline-flex justify-center font-medium text-blue";
const buttonSizeClasses = {
  sm: "text-sm py-2 px-4",
  md: "text-md py-3 px-6",
};

type ButtonTags = {
  a: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  button: React.ButtonHTMLAttributes<HTMLButtonElement>;
};

export function Button<T extends keyof ButtonTags>({
  tag: Tag,
  outline = false,
  size = "md",
  className,
  ...attrs
}: {
  tag: T | typeof Link;
  outline?: boolean;
  size?: string;
  className?: string;
  disabled?: boolean;
} & ButtonTags[T]) {
  const classes = classnames(
    className,
    outline ? buttonOutlineClasses : buttonClasses,
    buttonSizeClasses[size],
    attrs.disabled
      ? "cursor-default disabled:opacity-80 opacity-80 bg-gray-400"
      : outline
      ? "hover:text-white hover:bg-blue focus:outline-blue focus:ring-2 focus:ring-offset-2 focus:ring-blue bg-transparent"
      : "hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue bg-blue"
  );
  return <Tag {...(attrs as any)} className={classes} />;
}

export function ButtonLink({
  outline = false,
  size = "md",
  className,
  children,
  target = "_self",
  onClick,
  ...attrs
}: {
  outline?: boolean;
  size?: string;
  className?: string;
  children: ReactNode;
  href?: string;
  disabled?: boolean;
  target?: string;
  onClick?: (any) => void;
}) {
  const classes = classnames(
    className,
    outline ? buttonOutlineClasses : buttonClasses,
    buttonSizeClasses[size],
    attrs.disabled
      ? "cursor-default disabled:opacity-80 opacity-80 bg-gray-400"
      : outline
      ? "hover:text-white hover:bg-blue focus:outline-blue focus:ring-2 focus:ring-offset-2 focus:ring-blue bg-transparent"
      : "hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue bg-blue"
  );
  return (
    (<Link {...(attrs as any)} onClick={onClick} target={target} className={classes}>

      {children}

    </Link>)
  );
}

export const PublicPage = () => {
  return <div>test</div>;
};

export const Divider = ({ text }) => {
  return (
    <div className="flex items-center">
      <hr className="flex-grow border-gray-300" />
      {text && <span className="mx-4 text-gray-500">{text}</span>}
      <hr className="flex-grow border-gray-300" />
    </div>
  );
};


/*
Public
- AppLogo
- PageHeader - title, children
- PageBody - children (panel content)
pageTitle?
pagetitleCentered?
<Public.Header title="foobar">
<Public.Body>
</Public.Body>
*/
interface PublicHeaderProps {
  title?: ReactNode;
}
const PublicHeader: FC<PublicHeaderProps> = ({ title, children }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mx-auto w-auto flex items-center self-center flex-col">
        <Link href="/">

          <Logo color="dark" height="48px" />

        </Link>
        {title && (
          <h1 className="mt-8 text-center text-4xl font-extrabold text-postdrop-dark">
            {title}
          </h1>
        )}
        {children && children}
      </div>
    </div>
  );
};

interface PublicBodyProps {
  title?: ReactNode;
  width?: string;
}
const PublicBody: FC<PublicBodyProps> = ({
  title,
  children,
  width = "sm:max-w-lg",
}) => {
  // max-w-4xl
  const classes = classnames(width, "mt-10 sm:mx-auto sm:w-full px-4 sm:px-6");
  return (
    <div className={classes}>
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg px-6 sm:px-10">
        {title && (
          <h2 className="text-4xl font-extrabold text-gray-900">{title}</h2>
        )}
        {children}
      </div>
    </div>
  );
};

interface PublicComponents {
  Header: typeof PublicHeader;
  Body: typeof PublicBody;
}

export const Public: FC<{}> & PublicComponents = ({ children }) => {
  // add min-height hack
  // min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8
  return (
    <div className="min-height-full min-h-screen flex flex-col py-8 justify-center sm:px-2 lg:px-4">
      {children}
    </div>
  );
};

Public.Header = PublicHeader;
Public.Body = PublicBody;

interface MetaProps {
  title?: string;
}

const defaultTitle = "Send, Inline, Test and Manage HTML Email Templates";
const titleTemplate = (title) =>
  `${title && title.length > 0 ? title : defaultTitle} | Postdrop`;
export const Meta: FC<MetaProps> = ({ title, children }) => {
  return (
    <Head>
      <title>{titleTemplate(title)}</title>
      <meta
        name="description"
        key="description"
        content="Built for email developers, designers and marketers. Use our HTML editor to inline your CSS and send test HTML emails to your inbox."
      />
      {children}
    </Head>
  );
};

export const ErrorBanner = ({ title, errors = [], children = undefined }) => {
  return (
    <div className="rounded-md bg-red-50 p-2 my-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-2">
          {errors.length > 0 ? (
            <p className="text-sm font-semibold text-red-800">{title}</p>
          ) : (
            <p className="text-sm text-red-800">{title}</p>
          )}
          {errors.length > 0 && (
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc pl-5 space-y-1">
                {errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          {children && children}
        </div>
      </div>
    </div>
  );
};

export const SuccessBanner = ({ title, items = [], className = "" }) => {
  const [isClosed, close] = useState(false);
  if (isClosed) {
    return null;
  }
  return (
    <div
      className={classnames(
        `rounded-md bg-green-50 p-2 my-2 relative`,
        className
      )}
    >
      <div className="absolute top-0 right-0 block">
        <button
          className="inline-flex p-2 text-green-500 hover:text-green-700 focus:outline-none"
          onClick={() => close(true)}
        >
          <span className="sr-only">Dismiss</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-2">
          {items.length > 0 ? (
            <p className="text-sm font-semibold text-green-800">{title}</p>
          ) : (
            <p className="text-sm text-green-800">{title}</p>
          )}
          {items.length > 0 && (
            <div className="mt-2 text-sm text-green-700">
              <ul className="list-disc pl-5 space-y-1">
                {items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ProgressBar = ({ value = 0 }) => (
  <div className="h-3 relative rounded-full overflow-hidden my-1">
    <div className="w-full h-full bg-gray-200 absolute" />
    <div
      id="bar"
      className="h-full bg-green-500 relative"
      style={{
        width: `${value}%`,
      }}
    />
  </div>
);

export const PageLoading = () => (
  <div className="min-h-screen flex flex-col py-8 sm:px-2 lg:px-4 justify-center animate-pulse">
    <Logo color="dark" height="32px" />
    <div className="my-4">
      <Spinner className="h-12" />
    </div>
  </div>
);

export const Spinner = ({ className }: { className: string }) => (
  <svg
    style={{
      margin: "auto",
      background: "none",
      display: "block",
      shapeRendering: "auto",
    }}
    className={className}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9166666666666666s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(30 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8333333333333334s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(60 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.75s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(90 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6666666666666666s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5833333333333334s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(150 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4166666666666667s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(210 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3333333333333333s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.25s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(270 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.16666666666666666s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(300 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.08333333333333333s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(330 50 50)">
      <rect
        x={48}
        y={24}
        rx={2}
        ry="2.7600000000000002"
        width={4}
        height={12}
        fill="#999999"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
);

{
  /* <div className="animate-pulse flex space-x-4">
  <div className="flex-1 space-y-8 py-1">
    <div className="h-4 bg-gray-300 rounded w-5/6" />
    <div className="space-y-4">
      <div className="h-4 bg-gray-300 rounded w-7/8" />
      <div className="h-4 bg-gray-300 rounded w-11/12" />
      <div className="h-4 bg-gray-300 rounded w-7/8" />
    </div>
    <div className="space-y-4">
      <div className="h-4 bg-gray-300 rounded w-11/12" />
      <div className="h-4 bg-gray-300 rounded w-7/8" />
      <div className="h-4 bg-gray-300 rounded w-11/12" />
      <div className="h-4 bg-gray-300 rounded w-7/8" />
    </div>
    <div className="space-y-4">
      <div className="h-4 bg-gray-300 rounded w-7/8" />
      <div className="h-4 bg-gray-300 rounded w-11/12" />
      <div className="h-4 bg-gray-300 rounded w-7/8" />
    </div>
  </div>
</div> */
}
