import Gravatar from 'react-gravatar';
import { Transition, Menu } from '@headlessui/react';
import Link from 'next/link';
import React, { useRef } from 'react';
import { useAuth } from '../auth';

const Avatar = () => {
  const { user, loading, logout } = useAuth();
  const menu = useRef<HTMLInputElement>();
  if (loading || !user) {
    return null;
  }
  return (
    <Menu>
      {({ open }) => (
        <>
          <span className="rounded-md shadow-sm">
            <Menu.Button ref={menu} className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
              <span className="sr-only">Open user menu</span>
              {user?.picture ? <img src={user?.picture} alt="avatar" className="h-9 w-9 rounded-full avatar" /> : <Gravatar
                email={user?.attributes?.email || user?.email}
                className="h-9 w-9 rounded-full avatar"
              />}
            </Menu.Button>
          </span>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              static
              className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
              <div className="px-4 py-3">
                <p className="text-sm leading-5">Signed in as</p>
                <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                  {loading ? 'loading' : user?.attributes?.email || user?.email}
                </p>
              </div>

              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href="/account"
                      onClick={() => menu.current && menu.current.click()}
                      className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}>

                      Account settings

                    </Link>
                  )}
                </Menu.Item>
                {/* <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#support"
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}>
                      Support
                    </a>
                  )}
                </Menu.Item> */}
                {/* <Menu.Item
                  as="span"
                  disabled
                  className="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-not-allowed opacity-50">
                  New feature (soon)
                </Menu.Item> */}
              </div>

              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#sign-out"
                      onClick={(e) => {
                        e.preventDefault();
                        logout();
                      }}
                      className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}>
                      Sign out
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default Avatar;