import React from "react";
import classnames from "classnames";

const Templates = ({ className }) => (
  <svg
    className={classnames(className)}
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 1.493a1 1 0 0 1 1-1l8 .015a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V1.493Zm14-.986 8-.014a1 1 0 0 1 1 1v5.014a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1Zm-4.293 22.707a1 1 0 0 0 .293-.707v-5a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v4.985a1 1 0 0 0 1 1l8 .014a1 1 0 0 0 .707-.292ZM14.5 10.508h8a1 1 0 0 1 1 1v10.986a1 1 0 0 1-1 1l-8 .014a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1Z"    />
  </svg>
);

export default Templates;
