import jwt from 'jsonwebtoken';
import { GraphQLError } from 'graphql';
import jwkToPem from 'jwk-to-pem';

const keys = [
  {
    alg: 'RS256',
    e: 'AQAB',
    kid: '2m6nQgaDDuGcV6eXyQ2fptzZzuJgfodLwXBxHXSe3w8=',
    kty: 'RSA',
    n: 'ueGUqO7gG5Sq9RE0GJLjcQFiRjFGkdJR6biInpqVKW6JNC5QEDAUWSFjSNOvIsdx8e0acx_y--CpoS3ExnvSNs6-oTjgdBdUphqBTxVBKM2dtEgxeSpWkHP4DEVVWx_uOI_IOBlzb5ZkzeB31kQrOazSXFHMqtvYyIJ8F6IVdB2lwuBT7GeoNg6VfHD-p1Cvt83gsAiAh7rXRD9hS6CmecijPn728Iw7RI7hFV6dqHyvAWj937GiwYAuP2e6jXMkoXrn47TIZwtzp7iRsub_6QWpybrsXH3ORxjidiuhjaSV36HdFSbUPNm2jNy5ua8YEVgCfMCJ9iEAAWoFCzkycw',
    use: 'sig',
  },
  {
    alg: 'RS256',
    e: 'AQAB',
    kid: 'ej4mW3Airj78aqOkJeiKyeaDY6ZMRlO4byxsbBuaBTU=',
    kty: 'RSA',
    n: 'hnN4W7I7SMaMMLfexPCv6DnA8QuUXtKZF52_hNjALNkx5LfXZ79rQeznqFNWmeEX3TisUYununcIv12CQWMTB-OTbRGXWYKyZWaOWoERAm2vRuBtb_t6WqeGMB3Rx-xTHmeQSlv65SuL_ByP-NzDqlPYwTtqs5-bEji7YAyCluoYkNAWdcVlyAt8er8ZV0_O_dq9hrgJyVG2lUWicEd4icOfUMKGdrvQ0fOZCVDmMt5oMVovzStUBlV2rkxMawJ-gF6taH14NI0TdKomati6zASCcFxXlsRk-McJtm-2WkSfzfXL5rp84a4xd0TMgRRHirz0Coa2s_ZDYv_UU0jt1Q',
    use: 'sig',
  },
];
const pems = {};
for (let i = 0; i < keys.length; i++) {
  let key_id = keys[i].kid;
  let modulus = keys[i].n;
  let exponent = keys[i].e;
  let key_type = keys[i].kty;
  let jwk = { kty: key_type, n: modulus, e: exponent };
  pems[key_id] = jwkToPem(jwk);
}
interface DecodedTokenHeader {
  kid?: string;
}
interface DecodedToken {
  header?: DecodedTokenHeader;
}

export interface DecodedSession {
  sub: string;
  email_verified: boolean;
  iss: string;
  'cognito:username'?: string;
  'custom:full_name'?: string;
  aud: string;
  event_id?: string;
  token_use?: string;
  auth_time?: number;
  'custom:team_name'?: string;
  exp: number;
  iat: number;
  email: string;
}

export const verifyToken = async (token: string): Promise<DecodedSession> => {
  const decodedToken = jwt.decode(token, { complete: true }) as DecodedToken;

  const issuer = 'https://cognito-idp.us-west-2.amazonaws.com/us-west-2_uxowyFae1';

  const pem = pems[decodedToken?.header?.kid];

  if (!pem) {
    return Promise.reject(new Error('Invalid Signing Key'));
  }

  return new Promise((resolve, reject) => {
    jwt.verify(
      token,
      pem,
      {
        issuer: issuer,
        maxAge: '3600000',
      },
      (err, payload) => {
        if (err) {
          return reject(
            new GraphQLError('Invalid Auth Token', {
              extensions: {
                code: 'UNAUTHENTICATED',
                myExtension: 'custom',
              },
            }),
          );
        }
        return resolve(payload as DecodedSession);
      },
    );
  });
};
