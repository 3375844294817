import '../styles/globals.css';
import { init } from '../lib/sentry';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { AppProps /*, AppContext */ } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { AuthProvider, PageAuth, initializeAmplify } from '../lib/auth';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/graphql/apollo';
import { Meta } from '../lib/ui';
import * as gtag from '../lib/gtag';

init();
initializeAmplify();

const DefaultLayout = ({ children }) => children;

function GlobalApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  const {
    Layout = DefaultLayout,
    requireLogin,
    layoutOptions,
  } = Component as any;
  return (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <PageAuth enabled={requireLogin}>
          <Layout options={layoutOptions}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
              <link rel="icon" href="/favicon.ico" />
            </Head>
            <Meta />
            <Component {...pageProps} />
            <Toaster
              position="bottom-right"
              toastOptions={{
                success: {
                  duration: 2500,
                },
                error: {
                  duration: 5000,
                },
                blank: {
                  duration: 5000,
                }
              }}
            />
          </Layout>
        </PageAuth>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default GlobalApp;
