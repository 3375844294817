import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useContext,
} from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import storage from './storage';
import { PageLoading } from './ui';
import * as Sentry from '@sentry/node';
import cookie from 'js-cookie'
import { JwtPayload } from "jsonwebtoken"
import { verifyToken } from './jwt';

const isServer = typeof window === 'undefined';

export const initializeAmplify = () =>
  Amplify.configure({
    Auth: {
      region: process.env.NEXT_PUBLIC_COGNITO_REGION,
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID,
    },
    ssr: true,
  });

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const credential = cookie.get('id_token')
  const setUserWrap = async (user) => {
    let token;
    try {
      if (credential) {
        token = await verifyToken(credential);
      }
      // init users
      if (!isServer && (user || token)) {
        Sentry.setUser({
          email: user?.attributes?.email || token?.email,
          id: user?.attributes?.sub || token?.sub,
        });
      }
      // @ts-ignore
      if (!isServer && (user || token) && window?.Intercom) {
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'dofnxiuk',
          email: user?.attributes?.email,
          user_id: user?.attributes?.sub,
          // created_at: created_at,
          // account_id: user.account_id,
          email_verified: user?.attributes?.email_verified,
          name: user?.attributes['custom:full_name'],
        });
      }
      // remove users
      if (!isServer && (!user || !token)) {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
      // @ts-ignore
      if (!isServer && (!user || !token) && window?.Intercom) {
        // @ts-ignore
        window.Intercom('shutdown');
        // @ts-ignore
        window.Intercom('boot', {
          app_id: 'dofnxiuk',
        });
      }
    } catch (_err) { }
    return token ? setUser(token) : setUser(user);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUserWrap(user))
      .catch(() => setUserWrap(null))
      .then(() => setLoading(false));
  }, []);


  useEffect(() => {
    const getUserWrap = async () => {
      try {
        const token = await verifyToken(credential);
        setUserWrap(token)
      } catch (error) {
        console.log(error, "ERR")
      }
    }
    if (credential) {
      getUserWrap()
    }
  }, [router])

  const login = (cognitoUser) => {
    storage.delete('signup.email');
    storage.delete('signup.password');
    setUserWrap(cognitoUser);
    return cognitoUser;
  };

  const logout = async () => {
    try {
      // @ts-ignore
      !isServer &&
        // @ts-ignore
        window?.Intercom('boot', {
          app_id: 'dofnxiuk',
        });
    } catch (error) { }
    if (credential) {
      const token: JwtPayload | string = await verifyToken(credential)
      if (token && typeof token !== "string" && token?.identities[0]?.providerName === "Google") {
        storage?.delete('id_token')
        storage?.delete('access_token')
        storage?.delete('refresh_token');
        fetch("/api/auth/sign-out").then((response) => {
          router.push('/login');
        }).catch((error) => { console.log(error, "err") })
      }
    } else {
      return Auth.signOut().then((data) => {
        router.push('/login');
        setUser(null);
        return data;
      })
    };
  };

  const reload = async () => {
    if (credential) {
      const token: JwtPayload | string = await verifyToken(credential)
      if (token && typeof token !== "string" && token?.identities[0]?.providerName === "Google") {
        setUserWrap(token)
        setLoading(false)
      }
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => setUserWrap(user))
        .catch(() => setUserWrap(null))
        .then(() => setLoading(false));
    }

  }

  const values = useMemo(() => ({ user, login, logout, loading, reload }), [
    user,
    loading,
  ]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component'
    );
  }
  return context;
};

export const PageAuth = ({ enabled = false, children }) => {
  const { user, loading } = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (enabled && !loading && !user) {
      router.push('/login');
    }
  }, [enabled, loading, user]);
  if (enabled && !user) {
    return (
      <>
        <PageLoading />
        <div className="invisible hidden">{children}</div>
      </>
    );
  }
  return children;
};
