import React from 'react';
import classnames from 'classnames';

const Verified = ({className}) => (
<svg className={classnames(className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.52 17.5C11.52 13.9101 14.3138 11 17.76 11C21.2045 11.0044 23.9958 13.912 24 17.5C24 21.0899 21.2063 24 17.76 24C14.3138 24 11.52 21.0899 11.52 17.5ZM18.2899 20.381L21.312 16.181L21.3168 16.179C21.5226 15.8932 21.5664 15.5147 21.4317 15.1862C21.2969 14.8576 21.0042 14.6289 20.6637 14.5862C20.3231 14.5434 19.9866 14.6932 19.7808 14.979L17.0496 18.773C17.0078 18.8308 16.9442 18.8672 16.8749 18.873C16.8055 18.8783 16.7375 18.8516 16.6887 18.8L15.4695 17.53C15.0943 17.1395 14.4864 17.1398 14.1115 17.5305C13.7367 17.9212 13.7369 18.5545 14.112 18.945L15.6653 20.567C15.9896 20.9089 16.4317 21.1016 16.8931 21.102H17.0199C17.5241 21.0667 17.9882 20.8032 18.2899 20.381Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.48 5C12.48 7.76142 10.331 10 7.68001 10C5.03035 9.99669 2.88318 7.76005 2.88 5C2.88 2.23858 5.02904 0 7.68001 0C10.331 0 12.48 2.23858 12.48 5ZM5.17977 3.92297C5.03988 3.98197 4.93477 4.10606 4.896 4.258C4.83258 4.49981 4.80029 4.74933 4.8 5C4.8031 6.55836 5.95111 7.85477 7.44202 7.98354C8.93294 8.11231 10.2702 7.03055 10.5197 5.494C10.5406 5.33754 10.4894 5.17996 10.3814 5.069C10.274 4.95837 10.1229 4.90688 9.97344 4.93C9.67821 4.97601 9.38013 4.99941 9.0816 5C7.8536 4.99189 6.65149 4.63135 5.60736 3.958C5.47805 3.87695 5.31966 3.86398 5.17977 3.92297Z" />
    <path d="M11.9453 12.021C11.9237 11.8797 11.8451 11.7549 11.7293 11.678C9.82846 10.4102 7.46578 10.1478 5.35111 10.9697C3.23644 11.7916 1.61623 13.602 0.977271 15.857C0.933767 16.0088 0.961916 16.1731 1.05315 16.2999C1.14438 16.4267 1.28789 16.5009 1.43999 16.5H9.73439C9.96653 16.5 10.1654 16.3269 10.2067 16.089C10.4383 14.7397 11.0004 13.4756 11.8387 12.419C11.9277 12.3085 11.9665 12.1635 11.9453 12.021Z" />
</svg>
);

export default Verified;