import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Logo from '../logo';
import TemplatesIcon from '../icons/templates';
import RecipientsIcon from '../icons/verified';
import TeamIcon from '../icons/users'
import CogIcon from '../icons/cog';
import Avatar from './avatar';

const activeLinkClasses = 'active bg-blue text-white';
const linkClasses =
  'text-blue-light hover:bg-blue hover:text-white group-hover:text-white';
const defaultClasses =
  'group rounded-md py-2 px-2 flex items-center text-md font-medium';
const getActiveLinkClasses = (asPath: string) => (path) => {
  if (asPath.startsWith(path)) {
    return classnames(defaultClasses, activeLinkClasses);
  }

  return classnames(defaultClasses, linkClasses);
};
const MenuItems = () => {
  const { asPath } = useRouter();

  const getLinkClasses = getActiveLinkClasses(asPath);
  return <>
    <Link href="/templates" className={getLinkClasses('/templates')}>

      {/* Current: "text-white", Default: "text-blue-light group-hover:text-white" */}
      <TemplatesIcon className="text-white mr-3 ml-2 h-6 w-6" />Templates
    </Link>
    <Link href="/recipients" className={getLinkClasses('/recipients')}>

      <RecipientsIcon className="text-blue-light group-hover:text-white mr-3 ml-2 h-6 w-6" />Recipients
    </Link>
    <Link href="/team" className={getLinkClasses('/team')}>

      <TeamIcon className="text-blue-light group-hover:text-white mr-3 ml-2 h-6 w-6" />Team
    </Link>
    <Link href="/account" className={getLinkClasses('/account')}>

      <CogIcon className="text-blue-light group-hover:text-white mr-3 ml-2 h-6 w-6" />Account Settings
    </Link>
  </>;
};

function Sidebar({ isOpen, toggleSidebar }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      toggleSidebar(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  return <>
    <div className="sidebar sidebar-mobile md:hidden">
      <Transition show={isOpen} className="fixed inset-0 z-40 flex">
        <Transition.Child
          onClick={() => toggleSidebar(false)}
          className="fixed inset-0"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="absolute inset-0 bg-gray-600 opacity-75" />
        </Transition.Child>
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="relative max-w-xs w-full bg-postdrop-dark pt-5 pb-4 flex-1 flex flex-col">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              onClick={() => toggleSidebar(false)}
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Close sidebar</span>
              {/* Heroicon name: outline/x */}
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-shrink-0 py-3 px-4 flex items-center self-center">
            <Link href="/templates">

              <Logo color="light" height="32px" />

            </Link>
          </div>
          <div className="mt-5 flex-1 h-0 overflow-y-auto">
            <nav className="px-2 space-y-1">
              {/* Current: "bg-blue text-white", Default: "text-blue-light hover:bg-blue hover:text-white" */}
              <MenuItems />
            </nav>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" />
        {/* Dummy element to force sidebar to shrink to fit close icon */}
      </Transition>
    </div>
    {/* Static sidebar for desktop */}
    <div className="sidebar sidebar-desktop hidden md:flex md:flex-shrink-0 bg-postdrop-dark">
      <div className="w-64 xl:w-72 flex flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
          <div className="flex-shrink-0 py-3 px-4 flex items-center self-center">
            <Link href="/">

              <Logo color="light" height="32px" />

            </Link>
          </div>
          <div className="flex-grow mt-5 flex flex-col">
            <nav className="flex-1 px-2 space-y-1">
              {/* Current: "bg-blue text-white", Default: "text-blue-light hover:bg-blue hover:text-white" */}
              <MenuItems />
            </nav>
          </div>
        </div>
      </div>
    </div>
  </>;
}
const Dashboard = ({ children, options = {} }) => {
  const isMounted = React.useRef(true);
  const [isOpen, toggleSidebar] = useState(false);
  const toggleIfMounted = (val) => {
    if (isMounted.current) {
      return toggleSidebar(val);
    }
  };
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="min-h-screen bg-postdrop-light overflow-hidden flex">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleIfMounted} />
      <div className="flex-1 max-w-full mx-auto w-0 flex flex-col md:px-8 xl:px-8">
        <div className="relative z-10 flex-shrink-0 h-12 bg-postdrop-light border-b md:border-none border-gray-300 flex">
          <button
            onClick={() => toggleSidebar(true)}
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: outline/menu-alt-2 */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </button>
          <div className="flex-1 flex justify-between px-4 md:px-0">
            <div className="flex-1 flex">
              <div className="md:hidden flex-1 flex justify-center items-center">
                <Link href="/">

                  <Logo color="dark" height="24px" />

                </Link>
              </div>

              {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                      id="search_field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                </div>
              </form> */}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button className="bg-postdrop-light p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">View notifications</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button> */}
              {/* Profile dropdown */}
              <div className="ml-3 relative">
                <Avatar />
                {/*
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            */}
                {/* <div
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu">
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem">
                    Your Profile
                  </a>
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem">
                    Settings
                  </a>
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem">
                    Sign out
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <main
          className="flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none"
          tabIndex={0}>
          <div className="pb-6 pt-6 md:pt-0">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
