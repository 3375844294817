import { useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { isEqual } from 'lodash';
import merge from 'deepmerge';
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const isProd = process.env.NODE_ENV === 'production';
let apolloClient;

const authenticatedFetch = async (uri: string, options: any) => {
  const session = await Auth.currentSession().catch(() => undefined);
  const token = session?.getIdToken()?.getJwtToken();
  const headers = {
    ...options.headers,
    'x-csrf-token': 'x',
  };
  // todo: logout when token not found
  if (token) {
    headers['authorization'] = token;
  }
  return fetch(uri, {
    ...options,
    headers,
  });
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (isProd) {
    return;
  }
  if (networkError) console.log(`[NETWORK_ERR]: ${networkError}`);
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions, path }) =>
      console.log(`[GRAPHQL_ERR]: Code: ${extensions?.code}, Message: ${message}, Path: ${path}`),
    );
});

function createIsomorphLink(context) {
  if (typeof window === 'undefined') {
    const { SchemaLink } = require('@apollo/client/link/schema');
    const { schema } = require('./schema');
    return new SchemaLink({ schema, context });
  } else {
    const httpLink = new HttpLink({
      uri: '/api/graphql',
      fetch: authenticatedFetch,
      // credentials: 'same-origin',
    });
    return from([errorLink, httpLink]);
  }
}

function createApolloClient(context) {
  const isServer = typeof window === 'undefined';
  return new ApolloClient({
    ssrMode: isServer,
    link: createIsomorphLink(context),
    cache: new InMemoryCache({
      typePolicies: {
        // Query: {
        //   fields: {
        //     listTemplates: {
        //       merge: (_existing, incoming) => incoming,
        //     },
        //   },
        // },
        // Template: {
        //   merge: true,
        //   keyFields: ['id'],
        // },
      },
    }),
  });
}

export function initializeApollo(initialState = null, context?) {
  const _apolloClient = apolloClient ?? createApolloClient(context);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

// export async function getServerSideProps(ctx) {
//   const apolloClient = initializeApollo(null, ctx)

//   await apolloClient.query({
//     query: ALL_POSTS_QUERY,
//     variables: allPostsQueryVars,
//   })

//   return addApolloState(apolloClient, {
//     props: {},
//   })
// }

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
