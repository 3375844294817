import React from 'react';
import classnames from 'classnames';

const Cog = ({ className }) => (
  <svg
    className={classnames(className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.638 9.5H22.5C23.3284 9.5 24 10.1716 24 11V13C24.0005 13.3982 23.8427 13.7802 23.5614 14.062C23.28 14.3437 22.8982 14.502 22.5 14.502H20.638C20.4532 15.1419 20.1973 15.7591 19.875 16.342L21.192 17.659C21.7776 18.2448 21.7776 19.1943 21.192 19.78L19.778 21.194C19.1922 21.7796 18.2428 21.7796 17.657 21.194L16.339 19.877C15.7562 20.1988 15.1394 20.4547 14.5 20.64V22.5C14.5 23.3284 13.8284 24 13 24H11C10.1716 24 9.5 23.3284 9.5 22.5V20.64C8.86022 20.4544 8.24309 20.1981 7.66 19.876L6.342 21.193C5.74652 21.7556 4.81548 21.7556 4.22 21.193L2.806 19.779C2.22043 19.1932 2.22043 18.2438 2.806 17.658L4.125 16.339C3.80252 15.7565 3.54657 15.1397 3.362 14.5H1.5C0.671573 14.5 0 13.8284 0 13V11C0 10.1716 0.671573 9.5 1.5 9.5H3.362C3.54665 8.86067 3.80259 8.24413 4.125 7.662L2.807 6.345C2.52549 6.06367 2.36732 5.68199 2.36732 5.284C2.36732 4.88601 2.52549 4.50433 2.807 4.223L4.222 2.809C4.80775 2.22343 5.75725 2.22343 6.343 2.809L7.661 4.126C8.24378 3.8042 8.86058 3.5483 9.5 3.363V1.5C9.5 0.671573 10.1716 0 11 0H13C13.8284 0 14.5 0.671573 14.5 1.5V3.363C15.1392 3.54787 15.7558 3.8038 16.338 4.126L17.656 2.809C18.2418 2.22343 19.1912 2.22343 19.777 2.809L21.192 4.222C21.4735 4.50333 21.6317 4.88501 21.6317 5.283C21.6317 5.68099 21.4735 6.06267 21.192 6.344L19.875 7.66C20.197 8.24305 20.4529 8.8602 20.638 9.5ZM7 12C7 14.7614 9.23858 17 12 17C14.7601 16.9967 16.9967 14.7601 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12Z"
    />
  </svg>
);

export default Cog;
