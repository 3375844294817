import React from 'react';
import classnames from 'classnames';

const Users = ({ className }) => (
  <svg
    className={classnames(className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9901 10.2199C15.9662 10.3786 15.8586 10.514 15.7058 10.5775C14.7986 10.9571 14.0074 11.5543 13.4077 12.312C13.3173 12.4282 13.1752 12.4966 13.0244 12.4966H10.9793C10.8285 12.4966 10.6865 12.4282 10.596 12.312C10.0083 11.5677 9.2359 10.9776 8.34966 10.596C8.19877 10.5315 8.09294 10.3968 8.06957 10.2394C8.0462 10.082 8.10858 9.92415 8.23466 9.82154C8.663 9.47381 9.14057 9.18662 9.65299 8.96862C9.73328 8.93429 9.78762 8.86041 9.79462 8.77607C9.80162 8.69174 9.76014 8.61048 9.68654 8.56431C8.07835 7.55873 7.34852 5.65397 7.89292 3.88318C8.43732 2.11239 10.1262 0.897522 12.0436 0.897522C13.9609 0.897522 15.6498 2.11239 16.1942 3.88318C16.7386 5.65397 16.0088 7.55873 14.4006 8.56431C14.3264 8.61025 14.2846 8.69181 14.2919 8.77644C14.2991 8.86106 14.3542 8.93495 14.4351 8.96862C14.9353 9.1823 15.4024 9.4619 15.8227 9.79939C15.9506 9.90207 16.0139 10.0613 15.9901 10.2199ZM9.92009 4.19757C9.85059 4.22007 9.79561 4.27198 9.77087 4.33846C9.67985 4.57964 9.63284 4.83416 9.63191 5.09077C9.62107 6.34988 10.6554 7.38722 11.962 7.42758C13.2686 7.46794 14.3698 6.49658 14.4427 5.23939C14.4463 5.16898 14.4163 5.10088 14.3612 5.05447C14.3061 5.00807 14.232 4.98846 14.16 5.00123C13.8327 5.0611 13.5004 5.092 13.1672 5.09354C12.0877 5.09388 11.0314 4.79141 10.1274 4.22308C10.066 4.18447 9.9896 4.17507 9.92009 4.19757Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85106 11.5597C8.88685 11.9479 10.3502 13.6731 10.3392 15.672C10.3353 17.077 9.59297 18.3844 8.36694 19.1455C8.29333 19.1917 8.25186 19.273 8.25885 19.3573C8.26585 19.4416 8.3202 19.5155 8.40048 19.5499C8.76733 19.7058 9.11643 19.8979 9.44219 20.1231C10.1812 20.6289 10.789 21.2923 11.218 22.0615C11.3359 22.2757 11.3288 22.5338 11.1991 22.7417C11.0695 22.9495 10.8363 23.0767 10.5845 23.0769H1.43627C1.18454 23.0767 0.951315 22.9495 0.821654 22.7417C0.691993 22.5338 0.684843 22.2757 0.802813 22.0615C1.23207 21.2929 1.83979 20.6302 2.5786 20.1249C2.90436 19.8997 3.25346 19.7076 3.62031 19.5517C3.70008 19.5169 3.75393 19.4431 3.76091 19.3591C3.76788 19.275 3.72688 19.1939 3.65385 19.1474C1.91305 18.0592 1.21898 15.934 1.99944 14.0818C2.7799 12.2296 4.81527 11.1715 6.85106 11.5597ZM3.88645 14.776C3.81695 14.7985 3.76197 14.8504 3.73723 14.9169C3.64621 15.1581 3.5992 15.4126 3.59827 15.6692C3.58743 16.9283 4.62178 17.9657 5.92835 18.006C7.23493 18.0464 8.33616 17.075 8.4091 15.8179C8.41259 15.7476 8.3826 15.6796 8.32765 15.6332C8.27225 15.5873 8.19842 15.5677 8.1264 15.5797C7.79907 15.6396 7.46674 15.6705 7.13356 15.672C6.05403 15.6723 4.99775 15.3699 4.09373 14.8015C4.03233 14.7629 3.95596 14.7535 3.88645 14.776Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3797 19.5498C20.7465 19.7058 21.0956 19.8979 21.4214 20.1231C22.1604 20.6289 22.7681 21.2923 23.1972 22.0615C23.3151 22.2757 23.308 22.5338 23.1783 22.7417C23.0487 22.9495 22.8154 23.0767 22.5637 23.0769H13.4155C13.1637 23.0767 12.9305 22.9495 12.8008 22.7417C12.6712 22.5338 12.664 22.2757 12.782 22.0615C13.211 21.2923 13.8188 20.6289 14.5578 20.1231C14.8835 19.8979 15.2326 19.7058 15.5995 19.5498C15.6793 19.5151 15.7331 19.4413 15.7401 19.3572C15.7471 19.2732 15.7061 19.1921 15.633 19.1455C14.0246 18.1401 13.2946 16.2353 13.8389 14.4644C14.3832 12.6935 16.0721 11.4785 17.9896 11.4785C19.907 11.4785 21.596 12.6935 22.1403 14.4644C22.6846 16.2353 21.9545 18.1401 20.3461 19.1455C20.2725 19.1917 20.231 19.273 20.238 19.3573C20.245 19.4416 20.2994 19.5155 20.3797 19.5498ZM15.8656 14.776C15.7961 14.7985 15.7412 14.8504 15.7164 14.9169C15.6254 15.1581 15.5784 15.4126 15.5775 15.6692C15.5666 16.9283 16.601 17.9657 17.9075 18.006C19.2141 18.0464 20.3153 17.075 20.3883 15.8178C20.3918 15.7476 20.3618 15.6796 20.3068 15.6332C20.2514 15.5873 20.1776 15.5677 20.1056 15.5797C19.7783 15.6396 19.4459 15.6705 19.1128 15.672C18.0332 15.6723 16.9769 15.3699 16.0729 14.8015C16.0115 14.7629 15.9351 14.7535 15.8656 14.776Z"
    />
  </svg>
);

export default Users;
